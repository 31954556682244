import React, { useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { AppContext } from 'contexts';

const useStyles = makeStyles({
  alert: {
    position: 'fixed',
    left: '50%',
    bottom: 50,
    transform: 'translate(-50%, 0)',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    zIndex: 4000,
  },
  message: {
    fontSize: 16,
    fontWeight: 500,
  },
});

function HvAlert() {
  const styles = useStyles();
  const appContext = useContext(AppContext);
  const { showAlert, alertContent } = appContext;
  if (!showAlert) return null;
  const getAlertType = () => {
    switch (alertContent.type) {
      case 'success':
        return 'success';
      case 'error':
        return 'error';
      default:
        return 'info';
    }
  };
  return (
    <Alert className={styles.alert} severity={getAlertType()} variant='filled'>
      <Typography className={styles.message}>{alertContent.message}</Typography>
    </Alert>
  );
}

export default HvAlert;
