import ServiceOffer from './ServiceOffer';

class ServiceOfferWrapper {
  id: number;

  broker_note: string;

  serviceOffer: ServiceOffer;

  service_offer_id: number;

  shooting_id: number;

  type_value_map: object;

  url: string;

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.serviceOffer) {
      this.serviceOffer = new ServiceOffer(this.serviceOffer);
    }
  }
}

export default ServiceOfferWrapper;
