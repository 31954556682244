import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { useUpdatePageSize } from 'hooks';
import { AppContextProvider } from 'contexts';

import 'styles/global.scss';
import FloorplanEditor from 'views/FloorplanEditor/index';

const Home = lazy(() => {
  return import('./views/Home');
});

const Videos = lazy(() => {
  return import('./views/Videos');
});

function App() {
  useUpdatePageSize();

  return (
    <AppContextProvider>
      <CookiesProvider>
        <Router>
          <Suspense fallback={<div />}>
            <Switch>
              <Route path='/' component={Home} exact />
              <Route path='/visit/:listingId*/video' component={Videos} />
              <Route path='/visit/:listingId/:esoft_order_id?' component={Home} />
              <Route path='/floorplan/:listingId*' component={FloorplanEditor} />
            </Switch>
          </Suspense>
        </Router>
      </CookiesProvider>
    </AppContextProvider>
  );
}

export default App;
