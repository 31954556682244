class ServiceOffer {
  id: number;

  available: boolean;

  can_be_standalone: boolean;

  description: string;

  description_fr: string;

  display_name: string;

  display_name_fr: string;

  gf_clause: string;

  increment_by: string;

  is_global: boolean;

  key_name: string;

  min_app_version: string;

  order_index: number;

  price: number;

  processing_time_in_minutes: number;

  require_mls: boolean;

  subscription_id: number;

  type: number;

  zoho_item_id: string;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }
}

export default ServiceOffer;
