/* eslint-disable no-async-promise-executor */
import AgoraRTC, { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';

const options = {
  // Pass your app ID here.
  appId: 'a672e085286d49ef98b852c23354b491',
};

class Agora {
  rtc: any;

  agoraInitialized: boolean;

  stream: any;

  constructor(data: object = {}) {
    // Depress log
    AgoraRTC.setLogLevel(4);

    Object.assign(this, data);

    this.rtc = {
      // For the local client.
      client: null,
      // For the local audio and video tracks.
      localAudioTrack: null,
      localVideoTrack: null,
    };

    this.rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

    this.agoraInitialized = false;
    this.stream = null;
  }

  init() {
    return new Promise(async (resolve, reject) => {
      try {
        // check if already init
        if (this.agoraInitialized) {
          resolve(true);
          return;
        }

        this.rtc.client.on(
          'user-published',
          async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
            // Subscribe to the remote user when the SDK triggers the "user-published" event
            await this.rtc.client.subscribe(user, mediaType);

            // If the remote user publishes an audio track.
            if (mediaType === 'audio') {
              // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
              const remoteAudioTrack = user.audioTrack;
              // Play the remote audio track.
              remoteAudioTrack.play();
            }
          },
        );

        // Listen for the "user-unpublished" event
        this.rtc.client.on(
          'user-unpublished',
          async (unsubscribed_user: IAgoraRTCRemoteUser) => {
            // Unsubscribe from the tracks of the remote user.
            // await this.rtc.client.unsubscribe(unsubscribed_user);
          },
        );

        this.rtc.client.on('user-joined', (users: IAgoraRTCRemoteUser) => {});

        this.rtc.client.on('user-left', (users: IAgoraRTCRemoteUser) => {});

        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });

        this.agoraInitialized = true;

        resolve(true);
      } catch (error) {
        reject(new Error('init-error'));
      }
    });
  }

  joinChannel(session_id: string, token: string) {
    return new Promise(async (resolve, reject) => {
      try {
        // Create a local audio track from the audio sampled by a microphone.
        this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

        // Join an RTC channel.
        await this.rtc.client.join(options.appId, session_id, token);

        // Publish the local audio tracks to the RTC channel.
        await this.rtc.client.publish([this.rtc.localAudioTrack]);

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  async leave() {
    try {
      if (this.rtc.localAudioTrack) {
        // Destroy the local audio track.
        this.rtc.localAudioTrack.close();
      }

      if (this.stream) {
        this.stream.getTracks()[0].stop();
      }

      // Leave the channel.
      await this.rtc.client.leave();
    } catch (error) {
      console.log(error);
    }
  }
}

export const AgoraRTCClient = new Agora();
