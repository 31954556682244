import { useState, useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { KEYS } from 'config/constants';

function useShowRotateIcon() {
  const [showRotateIcon, setShowRotateIcon] = useState('show');

  const hideRotateIcon = () => {
    if (showRotateIcon === 'hide') return;

    setShowRotateIcon('hide');
    reactLocalStorage.set(KEYS.SHOW_ROTATE_ICON, 'hide');
  };

  useEffect(() => {
    const show = reactLocalStorage.get(KEYS.SHOW_ROTATE_ICON, 'show');
    setShowRotateIcon(show);
  }, []);

  return { showRotateIcon, hideRotateIcon };
}

export default useShowRotateIcon;
