class Marker {
  id: number;

  room: number;

  first_marker: boolean;

  floorplan_room_id: number;

  listing_id: number;

  lowres_media_url: string;

  order_index: number;

  orientation: number;

  shooting_id: number;

  state: number;

  x: number;

  y: number;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }
}

export default Marker;
