import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { getDate } from 'utils';
import { KEYS } from 'config/constants';

function useSession() {
  let _uuid = Cookies.get(KEYS.UUID);
  if (!_uuid) {
    _uuid = uuidv4();
    Cookies.set(KEYS.UUID, _uuid, {
      expires: getDate(365),
      secure: true,
      sameSite: 'none',
    });
  }

  // let _session = cookies.session;
  let _session = Cookies.get(KEYS.SESSION);
  if (!_session) {
    _session = uuidv4();
    Cookies.set(KEYS.SESSION, _session, {
      secure: true,
      sameSite: 'none',
    });
  }

  return { uuid: _uuid, session: _session };
}

export default useSession;
