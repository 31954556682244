import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import $ from 'jquery';
import _ from 'lodash';

function useUpdatePageSize() {
  const updatePageSize = _.debounce(() => {
    if (window.innerHeight) {
      $('#root').height(window.innerHeight);
    }
  }, 500);

  useEffect(() => {
    if (!isMobile) return _.noop;

    updatePageSize();

    window.addEventListener('resize', updatePageSize);
    return () => {
      window.removeEventListener('resize', updatePageSize);
    };
  }, []);
}

export default useUpdatePageSize;
