import Marker from './Marker';

export const HIDE_MEASURES_ROOMS = [
  'cour arrière',
  'facade',
  'piscine',
  'stationnement',
  "vue d'ensemble",
  'cour avant',
  'parc',
  'jardin',
  "bord de l'eau",
  'tennis',
];

class Room {
  id: number;

  roomname: string;

  x: number;

  y: number;

  width: number;

  width_inches: number;

  height: number;

  height_inches: number;

  listing_id: number;

  floor: number;

  floor_type: string;

  irregular: boolean;

  markers: Marker[];

  rejected: boolean;

  shooting_id: number;

  temp_id: string;

  two_d_pic_count: number;

  created_at: string;

  updated_at: string;

  valideMarker: boolean;

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.markers) {
      this.markers = this.markers.map((m: object) => {
        return new Marker(m);
      });
    }
  }

  hasValidMarkers() {
    const invalidMarker = this.markers.find((marker: Marker) => {
      return !marker.hasOwnProperty('lowres_media_url') || marker.lowres_media_url === '';
    });
    return !invalidMarker && this.markers.length > 0;
  }

  static getIcon(roomname: string) {
    roomname = roomname.toLowerCase();

    let roomIconName: string;
    switch (roomname) {
      case "salle d'eau":
      case 'salle de bain':
        roomIconName = 'bathroom';
        break;
      case 'salle à manger':
      case 'coin-repas':
      case 'coin repas':
      case 'cuisine':
        roomIconName = 'dining room';
        break;
      case 'cour arrière':
        roomIconName = 'backyard';
        break;
      case 'salle de lavage':
      case 'salle à lavage':
      case 'waterroom':
        roomIconName = 'water room';
        break;
      case 'espace commun':
      case 'salon':
      case 'salle familiale':
        roomIconName = 'salle familiale';
        break;
      case 'parc':
      case 'cour avant':
        roomIconName = 'frontyard';
        break;
      case 'chambre des maîtres':
        roomIconName = 'master bedroom';
        break;
      case 'rangement':
      case 'remise':
        roomIconName = 'storage';
        break;
      case 'balcon':
        roomIconName = 'balcony';
        break;
      case 'bureau':
        roomIconName = 'office';
        break;
      case 'terrace':
      case 'terrasse':
        roomIconName = 'terrasse';
        break;
      case 'piscine':
        roomIconName = 'pool';
        break;
      case 'cinéma maison':
        roomIconName = 'home theatre';
        break;
      case 'salle de formation':
        roomIconName = 'gym';
        break;
      case "hall d'entrée":
        roomIconName = 'entrée';
        break;
      case 'salle de jeux':
      case 'playroom':
        roomIconName = 'play room';
        break;
      case 'piscine intérieure':
        roomIconName = 'indoor pool';
        break;
      case 'stationnement':
        roomIconName = 'parking';
        break;
      case 'chambre':
        roomIconName = 'bedroom';
        break;
      case 'salle de réception':
        roomIconName = 'réception';
        break;
      default:
        roomIconName = '';
    }
    return roomIconName;
  }

  get isHideMeasurements(): boolean {
    return HIDE_MEASURES_ROOMS.includes(this.roomname.toLowerCase());
  }
}

export default Room;
