import ServiceOfferWrapper from './ServiceOfferWrapper';

class Shooting {
  id: number;

  assigned_part_time: boolean;

  billable: boolean;

  billable_km: number;

  blue_hour: boolean;

  broker_present: boolean;

  cancellation_reason: string;

  client_email: string;

  client_present: boolean;

  completed: boolean;

  current_pp_tech_id: string;

  dispatch_time: string;

  distance: number;

  distance_from_depot: number;

  distance_surcharge: string;

  drone: boolean;

  endtime: string;

  estimated_duration: number;

  force_assigned: boolean;

  ignore_scheduler: boolean;

  include_measures: boolean;

  is_public_for_broker: boolean;

  key_box: string;

  key_box_number: string;

  listing_id: number;

  media_email_sent: boolean;

  media_email_sent_at: string;

  note: string;

  only_360: boolean;

  ping_sent: boolean;

  ping_time: string;

  plex_unit_count: number;

  pp_tech_id: number;

  previous_shooting_id: string;

  previous_shooting_note: string;

  priority: string;

  quick_reshoot: boolean;

  reshoot_note: string;

  retouch: boolean;

  room_count: number;

  scheduleddate: string;

  sd_card: string;

  seasonal: boolean;

  service_offers: ServiceOfferWrapper[];

  square_footage: null;

  starttime: string;

  state: number;

  tech_id: number;

  tech_note: string;

  transit_time: number;

  uploading: boolean;

  use_backup: boolean;

  zd_ticket_id: string;

  zone_id: number;

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.service_offers) {
      this.service_offers = this.service_offers.map((m: object) => {
        return new ServiceOfferWrapper(m);
      });
    }
  }
}

export default Shooting;
