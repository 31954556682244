import { FloorPlanStateData } from 'types';
import Room from './Room';
import Marker from './Marker';
import Floor from './Floor';
import FloorPlanRoom from './FloorPlanRoom';

class Building {
  floorPlanRooms: object;

  markers: Marker[];

  sortedRooms: Room[];

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.floorPlanRooms) {
      for (const [key, value] of Object.entries(this.floorPlanRooms)) {
        this.floorPlanRooms[key] = new FloorPlanRoom(value);
      }
    }

    if (this.sortedRooms) {
      this.sortedRooms = this.sortedRooms.map((m: object) => {
        return new Room(m);
      });
    }

    if (this.markers) {
      this.markers = this.markers.map((m: object) => {
        return new Marker(m);
      });
    }
  }

  getFloors(currentFloor: number): Floor[] {
    const floors: Floor[] = Object.keys(this.floorPlanRooms)
      .sort((a: string, b: string) => {
        return Number(b) - Number(a);
      })
      .map((value: string) => {
        return new Floor({
          id: value,
          name: Floor.getName(value),
          selected: value === String(currentFloor),
        });
      });

    return floors;
  }

  generateFloorPlan(floor?: number) {
    const _floorPlanRooms = this.floorPlanRooms;

    let floorRooms = [];
    if (_floorPlanRooms[floor]) {
      floorRooms = _floorPlanRooms[floor].rooms;
    }

    let minX = 10000;
    let maxX = -10000;
    let minY = 10000;
    let maxY = -10000;

    for (let i = 0; i < floorRooms.length; i++) {
      const { x } = floorRooms[i];
      const { y } = floorRooms[i];
      const width = floorRooms[i].width * 20;
      const height = floorRooms[i].height * 20;

      if (x < minX) {
        minX = x;
      }
      if (x + width > maxX) {
        maxX = x + width;
      }
      if (y < minY) {
        minY = y;
      }
      if (y + height > maxY) {
        maxY = y + height;
      }
    }

    return {
      min_x: minX,
      max_x: maxX,
      min_y: minY,
      max_y: maxY,
      offset_x: -minX,
      offset_y: -minY,
      floorplanRawHeight: maxY - minY,
      floorplanRawWidth: maxX - minX,
    } as FloorPlanStateData;
  }
}

export default Building;
