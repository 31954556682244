import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { AppContext } from 'contexts';
import { HvAlert } from 'components';
import { KEYS } from 'config/constants';
import FloorplanEditor from './components/FloorplanEditor';
import AdminLogin from './components/AdminLogin';

interface Params {
  listingId: string;
}

function FloorplanEditorIndex() {
  const { listingId } = useParams<Params>();

  const appContext = useContext(AppContext);
  const { setListingId, adminUserToken, adminLoginModalOpen } = appContext;
  useEffect(() => {
    let listing_id;
    if (Number(listingId)) {
      listing_id = Number(listingId);
    } else {
      const text = decodeURIComponent(listingId);
      const decrypted = AES.decrypt(text, KEYS.CRYPTO_KEY);
      const decryptedText = decrypted.toString(Utf8);
      listing_id = Number(decryptedText);
    }
    setListingId(listing_id);
  }, [adminUserToken]);

  return (
    <div>
      {adminUserToken ? <FloorplanEditor /> : <AdminLogin />}
      <HvAlert />
    </div>
  );
}

export default FloorplanEditorIndex;
