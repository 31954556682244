import { useState, useEffect } from 'react';
import { WindowDimentions } from 'types';

function getWindowDimensions(): WindowDimentions {
  const { innerWidth, innerHeight } = window;
  return { width: innerWidth, height: innerHeight };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      return window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowDimensions;
}

export default useWindowDimensions;
