// eslint-disable-next-line import/no-extraneous-dependencies
import localIpUrl from 'local-ip-url';

export const LOCAL_IP_ADDRESS = localIpUrl('private', 'ipv4');
export const BASE_URL = 'https://hausvalet.ca/';
export const API_BASE_URL = 'https://api.hauslifenetwork.com/hb/api';
export const API_BASE_URL_LOCAL = `http://${LOCAL_IP_ADDRESS}:3001/hb/api`;
export const API_BASE_URL_DEV = 'https://api-dev.hauslifenetwork.com/hb/api';
export const API_BASE_URL_STAGING = 'https://api-staging.hauslifenetwork.com/hb/api';

export function getAPIURL() {
  if (window.location.href.indexOf('localhost') > -1) return API_BASE_URL_LOCAL;
  if (window.location.href.indexOf('devvisite') > -1) return API_BASE_URL_DEV;
  if (window.location.href.indexOf('stagingvisite') > -1) return API_BASE_URL_STAGING;

  return API_BASE_URL;
}

export const KEYS = {
  // cookies
  UUID: 'uuid',
  SESSION: 'session',

  // localstorage
  SHOW_ROTATE_ICON: 'SHOW_ROTATE_ICON',
  TOKEN: 'vr_token',
  ADMIN_TOKEN: 'admin_token',
  LANGUAGE: 'language',
  CRYPTO_KEY: 'HelloJiCommentCaVaMerci123!',
  CAPTCHA_KEY: '6Le9Pw4lAAAAAKaRqtcfeKZHtn92Oj_xEYy76P8r',
};

export const FLOOR_LEVELS = [-2, -1, 0, 1, 2, 3, 4, 5];
export const ROLES = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  BROKER: 3,
  TECH_CAPTURE: 4,
  TECH_POSTPROD: 5,
  CLIENT: 6,
  TECH_INJECTOR: 7,
  TECH_POSTPROD_LEAD: 8,
};

export const FLOOR_TYPES = [
  {
    key: 'Ardoise',
    fr: 'Ardoise',
    en: 'Slate',
  },
  {
    key: 'Béton',
    fr: 'Béton',
    en: 'Concrete',
  },
  {
    key: 'Bois',
    fr: 'Bois',
    en: 'Wood',
  },
  {
    key: 'Céramique',
    fr: 'Céramique',
    en: 'Ceramic',
  },
  {
    key: 'Granite',
    fr: 'Granite',
    en: 'Granite',
  },
  {
    key: 'Linoléum/Vinyle/Couvre-Planchers Souple',
    fr: 'Linoléum/Vinyle/Couvre-Planchers Souple',
    en: 'Linoleum/Vinyl/Flexible Floor Coverings',
  },
  {
    key: 'Marbre',
    fr: 'Marbre',
    en: 'Marble',
  },
  {
    key: 'Parqueterie',
    fr: 'Parqueterie',
    en: 'Parqueterie',
  },
  {
    key: 'Plancher flottant',
    fr: 'Plancher flottant',
    en: 'Floating floor',
  },
  {
    key: 'Tapis',
    fr: 'Tapis',
    en: 'Carpet',
  },
  {
    key: 'Tuile',
    fr: 'Tuile',
    en: 'Tile',
  },
  {
    key: 'Époxy',
    fr: 'Epoxy',
    en: 'Epoxy',
  },
  {
    key: 'Autre',
    fr: 'Autre',
    en: 'Other',
  },
  {
    key: 'Aucun',
    fr: 'Aucun',
    en: 'None',
  },
];

export const FLOOR_ROOMS = [
  {
    key: 'Facade',
    fr: 'Façade',
    en: 'Facade',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: false,
  },
  {
    key: 'Entrée',
    fr: 'Entrée',
    en: 'Entrance',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Salon',
    fr: 'Salon',
    en: 'Living room',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Salle à manger',
    fr: 'Salle à manger',
    en: 'Dining room',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Cuisine',
    fr: 'Cuisine',
    en: 'Kitchen',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Chambre des maîtres',
    fr: 'Chambre principale',
    en: 'Master bedroom',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Chambre',
    fr: 'Chambre',
    en: 'Bedroom',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Salle de bain attenante',
    fr: 'Salle de bains attenante',
    en: 'Adjoining bathroom',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Salle de bain',
    fr: 'Salle de bains',
    en: 'Bathroom',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: "Salle d'eau",
    fr: "Salle d'eau",
    en: 'Washroom',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Walk In',
    fr: 'Dressing',
    en: 'Walk In',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Salle de lavage',
    fr: 'Salle de lavage',
    en: 'Laundry room',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Rangement',
    fr: 'Rangement',
    en: 'Storage',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Bureau',
    fr: 'Bureau',
    en: 'Office',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Salle familiale',
    fr: 'Salle familiale',
    en: 'Family room',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Garage',
    fr: 'Garage',
    en: 'Garage',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Patio',
    fr: 'Patio',
    en: 'Patio',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: false,
  },
  {
    key: 'Terrasse',
    fr: 'Terrasse',
    en: 'Terrace',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: false,
  },
  {
    key: 'Balcon',
    fr: 'Balcon',
    en: 'Balcony',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: false,
  },
  {
    key: 'Piscine',
    fr: 'Piscine',
    en: 'Pool',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: false,
  },
  {
    key: 'Remise',
    fr: 'Remise',
    en: 'Shed',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: true,
  },
  {
    key: 'Cour arrière',
    fr: 'Cour arrière',
    en: 'Back yard',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: false,
  },
  {
    key: 'Cour avant',
    fr: 'Cour avant',
    en: 'Front yard',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: false,
  },
  {
    key: "Vue d'ensemble",
    fr: "Vue d'ensemble",
    en: 'Overall view',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Veranda',
    fr: 'Véranda',
    en: 'Veranda',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: false,
  },
  {
    key: 'Parc',
    fr: 'Parc',
    en: 'Park',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: false,
  },
  {
    key: 'Spa',
    fr: 'Spa',
    en: 'Spa',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: false,
  },
  {
    key: "Hall d'entrée",
    fr: "Hall d'entrée",
    en: 'Hallway',
    width: 10,
    height: 10,
    inside: true,
    residential: false,
    commercial: true,
  },
  {
    key: 'Stationnement',
    fr: 'Stationnement',
    en: 'Parking',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: true,
  },
  {
    key: 'Quai de chargement',
    fr: 'Quai de chargement',
    en: 'Loading dock',
    width: 10,
    height: 10,
    inside: false,
    residential: false,
    commercial: true,
  },
  {
    key: 'Chambre double',
    fr: 'Chambre double',
    en: 'Double bedroom',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Gym',
    fr: 'Gym',
    en: 'Gym',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Piscine intérieure',
    fr: 'Piscine intérieure',
    en: 'Indoor pool',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Salle de jeux',
    fr: 'Salle de jeux',
    en: 'Play room',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Cinéma maison',
    fr: 'Cinéma maison',
    en: 'Home theater',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Solarium',
    fr: 'Solarium',
    en: 'Solarium',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Couloir',
    fr: 'Couloir',
    en: 'Corridor',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Sauna',
    fr: 'Sauna',
    en: 'Sauna',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Salle électrique',
    fr: 'Salle électrique',
    en: 'Electric room',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Boudoir',
    fr: 'Boudoir',
    en: 'Boudoir',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Garde-Robe',
    fr: 'Garde-Robe',
    en: 'Wardrobe',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Coin-repas',
    fr: 'Coin-repas',
    en: 'Dining area',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Réception',
    fr: 'Réception',
    en: 'Reception',
    width: 10,
    height: 10,
    inside: true,
    residential: false,
    commercial: true,
  },
  {
    key: 'Salle de réception',
    fr: 'Salle de réception',
    en: 'Reception hall',
    width: 10,
    height: 10,
    inside: true,
    residential: false,
    commercial: true,
  },
  {
    key: 'Salle de formation',
    fr: 'Salle de formation',
    en: 'Training room',
    width: 10,
    height: 10,
    inside: true,
    residential: false,
    commercial: true,
  },
  {
    key: 'Aire de jeux',
    fr: 'Aire de jeux',
    en: 'Playground',
    width: 10,
    height: 10,
    inside: true,
    residential: false,
    commercial: true,
  },
  {
    key: 'Chambre froide',
    fr: 'Chambre froide',
    en: 'Cold room',
    width: 10,
    height: 10,
    inside: true,
    residential: false,
    commercial: true,
  },
  {
    key: 'Comptoir',
    fr: 'Comptoir',
    en: 'Counter',
    width: 10,
    height: 10,
    inside: true,
    residential: false,
    commercial: true,
  },
  {
    key: 'Station de gaz',
    fr: 'Station de gaz',
    en: 'Gas station',
    width: 10,
    height: 10,
    inside: false,
    residential: false,
    commercial: true,
  },
  {
    key: 'Surface de vente',
    fr: 'Surface de vente',
    en: 'Sales area',
    width: 10,
    height: 10,
    inside: true,
    residential: false,
    commercial: true,
  },
  {
    key: 'Entrepôt',
    fr: 'Entrepôt',
    en: 'Warehouse',
    width: 10,
    height: 10,
    inside: true,
    residential: false,
    commercial: true,
  },
  {
    key: 'Cave à vin',
    fr: 'Cave à vin',
    en: 'Wine cellar',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: false,
  },
  {
    key: 'Atelier',
    fr: 'Atelier',
    en: 'Workshop',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Espace commun',
    fr: 'Espace commun',
    en: 'Common area',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Sous-Sol semi fini',
    fr: 'Sous-sol semi fini',
    en: 'Semi finished basement',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Coin repas',
    fr: 'Coin repas',
    en: 'Dining area',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Atelier',
    fr: 'Atelier',
    en: 'Workshop',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Bar',
    fr: 'Bar',
    en: 'Bar',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Bibliotheque',
    fr: 'Bibliothèque',
    en: 'Library',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Face arriere',
    fr: 'Face arrière',
    en: 'Back facade',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Mezzanine',
    fr: 'Mezzanine',
    en: 'Mezzanine',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Salle de bains attenante',
    fr: 'Salle de bains attenante',
    en: 'Ensuite bathroom',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: "Vue d'ensemble",
    fr: "Vue d'ensemble",
    en: 'Overall view',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Jardin',
    fr: 'Jardin',
    en: 'Garden',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: true,
  },
  {
    key: "Bord de l'eau",
    fr: "Bord de l'eau",
    en: 'Waterfront',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: true,
  },
  {
    key: 'Salle communautaire',
    fr: 'Salle communautaire',
    en: 'Common room',
    width: 10,
    height: 10,
    inside: true,
    residential: true,
    commercial: true,
  },
  {
    key: 'Tennis',
    fr: 'Tennis',
    en: 'Tennis',
    width: 10,
    height: 10,
    inside: false,
    residential: true,
    commercial: true,
  },
];

export const shapes = [
  {
    key: 0,
    value: true,
  },
  {
    key: 1,
    value: false,
  },
];
