import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#0005',
    zIndex: 1000,

    '&.transparent': {
      background: 'transparent',
    },
  },
});

interface Props {
  className: string;
  size: number;
}

function PleaseWait(props: Props) {
  const styles = useStyles();

  const { className, size } = props;

  return (
    <div className={classNames(className, styles.root)}>
      <CircularProgress size={size} color='primary' thickness={2} />
    </div>
  );
}

PleaseWait.defaultProps = {
  className: null,
  size: 60,
};

PleaseWait.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default PleaseWait;
