import Room from './Room';

class FloorPlanRoom {
  minIndex: number;

  rooms: Room[];

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.rooms) {
      this.rooms = this.rooms.map((m: object) => {
        return new Room(m);
      });
    }
  }
}

export default FloorPlanRoom;
