import ReactGA from 'react-ga4';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Ford Antenna',
  },
  palette: {
    primary: {
      main: '#00bdf2',
    },
    secondary: {
      main: '#cf142b',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

ReactGA.initialize('G-XWR4DC2243');
ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname,
});
