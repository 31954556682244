class QRCode {
  id: number;

  qr_code: string;

  broker_id: number;

  listing_id: number;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }

  get imageURL(): string {
    return `https://cdn.hauslifenetwork.com/qr_codes/${this.qr_code}.png`;
  }
}

export default QRCode;
