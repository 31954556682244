import { useState } from 'react';

function useSensor() {
  const [sensorEnabled, setSensorEnabled] = useState(false);

  const enableSensor = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (isIOS && typeof DeviceOrientationEvent?.requestPermission === 'function') {
      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === 'granted') {
            setSensorEnabled(true);
          }
        })
        .catch(() => {
          setSensorEnabled(false);
        });
    }
  };

  return { sensorEnabled, enableSensor };
}

export default useSensor;
