import Room from './Room';
import Broker from './Broker';
import Shooting from './Shooting';
import QRCode from './QRCode';

class Listing {
  id: number;

  address: string;

  app: string;

  city: string;

  broker: Broker;

  broker_id: number;

  expired: boolean;

  floorplanRooms: Room[];

  shootings: Shooting[];

  sold: boolean;

  qr_code: QRCode;

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.floorplanRooms) {
      this.floorplanRooms = this.floorplanRooms.map((m: object) => {
        return new Room(m);
      });
    }

    if (this.shootings) {
      this.shootings = this.shootings.map((m: object) => {
        return new Shooting(m);
      });
    }

    if (this.qr_code) {
      this.qr_code = new QRCode(this.qr_code);
    }
  }
}

export default Listing;
