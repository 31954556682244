import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'contexts';
import { makeStyles, Box, Typography, TextField, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    login: {
      width: 300,
      position: 'relative',
      top: '20%',
      marginLeft: 'auto',
      marginRight: 'auto',
      alignContent: 'center',
      backgroundColor: 'white',
      padding: 30,
      animation: 'slide-down .2s ease-in',

      '@media (max-width: 767px)': {
        top: 101,
      },
    },
    button: {
      fontSize: '12px',
      color: 'white',
      marginTop: 5,
      marginBottom: 5,
    },
    verifyButton: {
      marginTop: 10,
    },
    resendMessage: {
      flexDirection: 'row',
    },
    warning: {
      fontSize: 12,
      color: 'red',
    },
  };
});

function AdminLogin() {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    error: false,
  });
  const { login } = appContext;

  const handleLogin = async () => {
    if (!email) {
      setErrors({ ...errors, email: true });
      return;
    }
    if (!password) {
      setErrors({ ...errors, password: true });
      return;
    }
    const res = await login(email, password);
    if (res == null) {
      setErrors({ ...errors, error: true });
    }
  };

  return (
    <Box className={classes.login}>
      <Typography variant='h5'>Login</Typography>
      <form>
        <TextField
          variant='outlined'
          value={email}
          onChange={(e) => {
            return setEmail(e.target.value);
          }}
          margin='normal'
          label='Email'
          fullWidth
          size='small'
          required
          error={errors.email}
        />
        <TextField
          variant='outlined'
          value={password}
          onChange={(e) => {
            return setPassword(e.target.value);
          }}
          margin='normal'
          label='Password'
          fullWidth
          size='small'
          required
          error={errors.password}
        />
        {errors.error && (
          <Typography className={classes.warning}>
            Échec de la connexion! Veuillez réessayer!
          </Typography>
        )}
        <Button
          type='button'
          fullWidth
          variant='outlined'
          color='primary'
          className={classes.verifyButton}
          disabled={!email || !password}
          onClick={() => {
            return handleLogin();
          }}
        >
          Login
        </Button>
      </form>
    </Box>
  );
}

export default AdminLogin;
