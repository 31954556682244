import React, { useState, useContext, useEffect, isValidElement } from 'react';
import { useParams } from 'react-router-dom';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { AppContext } from 'contexts';
import { useWindowDimensions } from 'hooks';
import {
  makeStyles,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Checkbox,
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ReorderIcon from '@material-ui/icons/Reorder';
import CloseIcon from '@material-ui/icons/Close';
import { Floor, Room, RoomPlan } from 'models';
import { WindowDimentions, FloorPlanDimensionsData } from 'types';
import { FLOOR_TYPES, FLOOR_ROOMS, FLOOR_LEVELS, KEYS } from 'config/constants';
import _ from 'lodash';
import { images } from 'images';
import classNames from 'classnames';

interface Params {
  listingId: string;
}

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      height: 32,
      width: 200,
      marginTop: 30,
    },
    floorplanContainer: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'space-between',
      width: '70%',
      padding: 20,
      backgroundColor: '#f1f1f1',
    },
    floors: {
      marginTop: 100,
    },
    buttonText: {
      color: 'white',
      textTransform: 'capitalize',
      fontSize: 12,
    },
    floorButtonText: {
      color: 'black',
      textTransform: 'capitalize',
    },
    floorButton: {
      marginBottom: 20,
      maxWidth: 200,
      backgroundColor: 'white',
    },
    selectedButtonText: {
      textTransform: 'capitalize',
      color: 'white',
    },
    currentFloorplanButton: {
      marginBottom: 20,
      maxWidth: 200,
      backgroundColor: '#00bdf2',
      '&:hover': {
        backgroundColor: '#00bdf2',
      },
    },
    rooms: {
      marginTop: 200,
    },
    roomBox: {
      position: 'absolute',
      display: 'flex',
      boxSizing: 'border-box',
    },
    room: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: '"Ford Antenna", sans-serif',
      cursor: 'pointer',
      pointerEvents: 'auto',
      margin: 1,

      '& img': {
        width: '20px',
        height: '20px',
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      width: '50%',
      marginBottom: 20,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    scrollableRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      overflowX: 'auto',
    },
    center: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    input: {
      marginRight: 10,
      width: 150,
    },
    inputsGroup: {
      marginLeft: 20,
    },
    button: {
      width: '60%',
      marginBottom: 10,
    },
    closeButton: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    select: {
      minWidth: 250,
    },
    subtitle: {
      fontSize: 12,
    },
    checkbox: {
      color: '#00bdf2',
      '&:Mui-checked': {
        color: 'yellow',
      },
    },
    list: {
      display: 'flex',
      color: 'white',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#00bdf2',
      padding: 5,
      borderRadius: 4,
      marginTop: 15,
      cursor: 'move',
    },
    findRoomButton: {
      marginLeft: 10,
    },
    slider: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightArrow: {
      position: 'absolute',
      top: '50%',
      right: '32px',
      fontSize: 5,
      fill: 'white',
      zIndex: 10,
      cursor: 'pointer',
      userSelect: 'none',
    },
    leftArrow: {
      position: 'absolute',
      top: '50%',
      left: '32px',
      fontSize: 10,
      fill: 'white',
      zIndex: 10,
      cursor: 'pointer',
      userSelect: 'none',
    },
    slide: {
      opacity: 0,
      transitionDuration: '1s ease',
      flexDirection: 'row',
      margin: 20,
    },
    activeSlide: {
      opacity: 1,
      transitionDuration: '1s',
      transform: 'scale(1.08)',
      flexDirection: 'row',
      margin: 20,
    },
    valid: {
      // position: 'absolute',
      // bottom: -5,
      marginTop: -5,
      padding: 2,
      textAlign: 'center',
      fontSize: 10,
      color: 'white',
      fill: 'white',
      backgroundColor: 'green',
      width: 'auto',
      // zIndex: 10,
      // cursor: 'pointer',
      // userSelect: 'none',
    },
  };
});

function FloorplanEditor() {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const windowDimensions: WindowDimentions = useWindowDimensions();
  const { listingId } = useParams<Params>();
  const {
    building,
    currentRoom,
    currentFloor,
    floorPlanState,
    getRoomDimensions,
    setCurrentRoom,
    setCurrentFloor,
    setListingId,
    updateFloorplanRoom,
    moveFloorplanRoom,
    updateCurrentRoom,
    updateMarkerOrder,
    copyFloorplanRoom,
    approveMarker,
    rejectMarker,
    getRoomMarkers,
    currentRoomMarkers,
    getListing,
    fetchNewListing,
    newListing,
    setAlert,
  } = appContext;
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [width_inches, setWidthInches] = useState(null);
  const [height_inches, setHeightInches] = useState(null);
  const [roomname, setRoomName] = useState('');
  const [irregular, setIrregular] = useState(false);
  const [floor_type, setFloorType] = useState('');
  const [floor, setFloorLevel] = useState(0);
  const [new_listing_id, setNewListingId] = useState(null);
  const [listing_id, setListingIdFromParams] = useState(null);
  const [isNewListingValid, setNewListingValid] = useState(false);
  const [shooting_id, setNewShootingId] = useState(null);
  const [newCopyListingId, setNewCopyListingId] = useState(null);
  const [keepRoom, setKeepRoom] = useState(false);
  const [newRoom, setNewRoom] = useState(null);
  const [newRoomId, setNewRoomId] = useState(null);
  const [newRoomName, setNewRoomName] = useState('');
  const [isReOrderRoomsModalOpen, setReorderRoomsModalOpen] = useState(false);
  const [isValidMarkerModalOpen, setValidMarkerModalOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [isMoveModalOpen, setMoveModalOpen] = useState(false);
  const [isCopyModalOpen, setCopyModalOpen] = useState(false);
  const [markersWithOrder, setMarkersWithOrder] = useState([]);

  const floor_types = FLOOR_TYPES.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });
  const floor_rooms = FLOOR_ROOMS.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });
  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);
  const rooms =
    building && building.floorPlanRooms && building.floorPlanRooms[currentFloor]
      ? building.floorPlanRooms[currentFloor].rooms
      : [];
  useEffect(() => {
    let listing_params;
    if (Number(listingId)) {
      listing_params = Number(listingId);
    } else {
      const text = decodeURIComponent(listingId);
      const decrypted = AES.decrypt(text, KEYS.CRYPTO_KEY);
      const decryptedText = decrypted.toString(Utf8);
      listing_params = Number(decryptedText);
    }
    setListingId(listing_params);
    setListingIdFromParams(listing_params);
    if (currentRoom) {
      setX(currentRoom.x);
      setY(currentRoom.y);
      setWidth(currentRoom.width);
      setHeight(currentRoom.height);
      setWidthInches(currentRoom.width_inches);
      setHeightInches(currentRoom.height_inches);
      setIrregular(currentRoom.irregular);
      setRoomName(currentRoom.roomname);
      setFloorType(currentRoom.floor_type);
      setFloorLevel(currentRoom.floor);
      setNewShootingId(currentRoom.shooting_id);
    }
    if (newListing && newListing.floorplanRooms) {
      const foundMatchedRoom = newListing.floorplanRooms.find((r) => {
        return r.id === currentRoom.id;
      });
      if (foundMatchedRoom) {
        setNewRoom(foundMatchedRoom);
        setNewRoomId(foundMatchedRoom.id);
        setNewRoomName(foundMatchedRoom.roomname);
      } else {
        setNewRoom(null);
        setNewRoomId(null);
        setNewRoomName('');
      }
    } else {
      setNewRoom(null);
      setNewRoomId(null);
      setNewRoomName('');
    }
    const _markers = [];
    if (building) {
      for (let i = 0; i < building.markers.length; i++) {
        _markers.push(building.markers[i].id);
      }
    }
    setMarkersWithOrder(_markers);
  }, [currentRoom, newListing, currentRoomMarkers]);
  if (!floorPlanState) return null;

  const floors = building ? building.getFloors(currentFloor) : [];
  const allRooms = [];
  const floorPlanRooms = building && building.floorPlanRooms;
  for (const [key, value] of Object.entries(floorPlanRooms)) {
    const floorRooms = value.rooms;
    for (let j = 0; j < floorRooms.length; j++) {
      const room = floorRooms[j];
      allRooms.push(room);
    }
  }
  const floorPlanDimensions: FloorPlanDimensionsData = Floor.getPlanDimensions(
    windowDimensions.width,
    windowDimensions.height,
  );

  const roomColor = 'white';
  const badMarkerColor = 'silver';

  const roomPlans: RoomPlan[] = rooms.map((room: Room) => {
    const floorPlanSize = {
      width: floorPlanDimensions.max_width,
      height: floorPlanDimensions.max_height,
    };
    const data = getRoomDimensions(room, floorPlanSize);

    if (data) {
      let cursor = 'pointer';
      let textColor = 'black';
      let bgColor = roomColor;

      if (!room.valideMarker || !room.markers.length) {
        cursor = 'default';
        bgColor = badMarkerColor;
      }
      if (room.rejected === true) {
        bgColor =
          'linear-gradient(176deg, rgba(248, 178, 166,0.7) 0%, rgba(244, 39, 16,0.7) 41%, rgba(245, 44, 18,0.7) 100%)';
      }

      const letterToWidthRatio = 0.5476;
      let fontSize = data.roomWidth / (room.roomname.length * letterToWidthRatio) - 3;
      if (fontSize > 20) {
        fontSize = 20;
      }

      let icon = null;
      const displayName = room.roomname;
      if (fontSize < 10) {
        if (data.roomWidth > 15 && data.roomHeight > 15) {
          icon = Room.getIcon(room.roomname);
        }
      }

      if (currentRoom && room.id === currentRoom.id) {
        bgColor = '#00bcf2';
        textColor = 'white';
      }

      return {
        ...data,
        room,
        icon,
        displayName,
        fontSize,
        textColor,
        background: bgColor,
        cursor,
      };
    }
    return null;
  });
  const handleXChange = (e) => {
    setX(Number(e.target.value));
    const updatedRoom = currentRoom;
    updatedRoom.x = Number(e.target.value);
    updateCurrentRoom(updatedRoom);
  };
  const handleXSave = async () => {
    if (x % 20 !== 0) {
      alert('X doit être un multiple de 20');
    } else {
      const updatedRoom = currentRoom;
      updatedRoom.x = x;
      const res = await updateFloorplanRoom(updatedRoom);
      if (res.status === 200) {
        setAlert('success', 'Saved X successfully!');
      } else {
        setAlert('error', 'Failed to save X!');
      }
    }
  };
  const handleYSave = async () => {
    if (y % 20 !== 0) {
      alert('Y doit être un multiple de 20');
    } else {
      const updatedRoom = currentRoom;
      updatedRoom.y = y;
      const res = await updateFloorplanRoom(updatedRoom);
      if (res.status === 200) {
        setAlert('success', 'Saved Y successfully!');
      } else {
        setAlert('error', 'Failed to save Y!');
      }
    }
  };
  const handleYChange = (e) => {
    setY(Number(e.target.value));
    const updatedRoom = currentRoom;
    updatedRoom.y = Number(e.target.value);
    updateCurrentRoom(updatedRoom);
  };
  const handleWidthChange = (e) => {
    let value = Math.floor(Number(e.target.value));
    if (value > 200) {
      value = 200;
    }
    if (value < 0) {
      value = 0;
    }
    setWidth(value);
    const updatedRoom = currentRoom;
    updatedRoom.width = value;
    updateCurrentRoom(updatedRoom);
  };
  const handleHeightChange = (e) => {
    let value = Math.floor(Number(e.target.value));
    if (value > 200) {
      value = 200;
    }
    if (value < 0) {
      value = 0;
    }
    const updatedRoom = currentRoom;
    updatedRoom.height = value;
    updateCurrentRoom(updatedRoom);
    setHeight(value);
  };
  const handleWidthInchesChange = (e) => {
    let value = Math.floor(Number(e.target.value));
    if (value > 11) {
      value = 11;
    }
    if (value < 0) {
      value = 0;
    }
    setWidthInches(value);
    const updatedRoom = currentRoom;
    updatedRoom.width_inches = value;
    updateCurrentRoom(updatedRoom);
  };
  const handleHeightInchesChange = (e) => {
    let value = Math.floor(Number(e.target.value));
    if (value > 11) {
      value = 11;
    }
    if (value < 0) {
      value = 0;
    }
    setHeightInches(value);
    const updatedRoom = currentRoom;
    updatedRoom.height_inches = value;
    updateCurrentRoom(updatedRoom);
  };
  const handleDimensionChange = (type, e) => {
    const value = Number(e.target.value);
    const updatedRoom = currentRoom;
    switch (type) {
      case 'width':
        updateFloorplanRoom(updatedRoom);
        break;
      case 'height':
        updatedRoom.height = value;
        updateCurrentRoom(updatedRoom);
        updateFloorplanRoom(updatedRoom);
        break;
      case 'width_inches':
        updatedRoom.width_inches = value;
        updateCurrentRoom(updatedRoom);
        updateFloorplanRoom(updatedRoom);
        break;
      case 'height_inches':
        updatedRoom.height_inches = value;
        updateCurrentRoom(updatedRoom);
        updateFloorplanRoom(updatedRoom);
        break;
      default:
        break;
    }
  };
  const handleChangeNewRoomId = (e) => {
    setNewRoomId(e.target.value);
  };
  const handleReorderSave = () => {
    const markersWithNewOrders = [];
    for (let i = 0; i < markersWithOrder.length; i++) {
      markersWithNewOrders.push({
        id: markersWithOrder[i],
        order_index: i + 1,
      });
    }
    updateMarkerOrder(markersWithNewOrders);
    getListing(Number(listing_id));
    setReorderRoomsModalOpen(false);
  };
  const handleMoveSave = async () => {
    const updatedRoom = currentRoom;
    if (new_listing_id) {
      updatedRoom.listing_id = new_listing_id;
    }
    if (shooting_id) {
      updatedRoom.shooting_id = shooting_id;
    }
    const res = await moveFloorplanRoom(updatedRoom, keepRoom);
    if (res && res.status !== 200) {
      setAlert('error', 'Please input existing listing_id!');
    } else {
      getListing(Number(listing_id));
      setMoveModalOpen(false);
    }
  };
  const handleCopySave = () => {
    const markers_to_copy = [];
    currentRoom.markers.map((marker) => {
      return markers_to_copy.push(marker.id);
    });
    const res = copyFloorplanRoom(newCopyListingId, markers_to_copy, newRoomId);
    setNewCopyListingId('');
    setNewRoom(null);
    setNewRoomId(null);
    setNewRoomName('');
    getListing(Number(listing_id));
    setCopyModalOpen(false);
  };
  const handleChangeListingId = (e) => {
    setNewListingId(e.target.value);
  };
  const handleChangeCopyListingId = (e) => {
    setNewCopyListingId(e.target.value);
    setNewRoomName('');
  };
  const handleFloorTypeSelect = (e) => {
    setFloorType(e.target.value);
    const updatedRoom = currentRoom;
    updatedRoom.floor_type = e.target.value;
    updateFloorplanRoom(updatedRoom);
  };
  const handleFloorSelect = (e) => {
    setFloorLevel(e.target.value);
    const updatedRoom = currentRoom;
    updatedRoom.floor = e.target.value;
    updateFloorplanRoom(updatedRoom);
    getListing(Number(listing_id));
  };
  const handleShapeSelect = (e) => {
    setIrregular(e.target.checked);
    const updatedRoom = currentRoom;
    updatedRoom.irregular = e.target.checked;
    updateFloorplanRoom(updatedRoom);
  };
  const handleRoomSelect = (e) => {
    setRoomName(e.target.value);
    const updatedRoom = currentRoom;
    updatedRoom.roomname = e.target.value;
    updateFloorplanRoom(updatedRoom);
  };
  const handleKeepRoomOption = (e) => {
    setKeepRoom(e.target.checked);
  };
  // const handle drag sorting
  const handleSort = () => {
    // duplicate items
    const _markerswithOrder = [...markersWithOrder];

    // remove and save the dragged item content
    const draggedItemContent = _markerswithOrder.splice(dragItem.current, 1)[0];
    // switch the position
    _markerswithOrder.splice(dragOverItem.current, 0, draggedItemContent);
    // reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;
    // update the actual array
    setMarkersWithOrder(_markerswithOrder);
  };
  const handleMoveRoom = () => {
    setMoveModalOpen(true);
  };
  const handleReorderRooms = () => {
    setReorderRoomsModalOpen(true);
  };
  const handleValidMarkers = () => {
    setValidMarkerModalOpen(true);
    getRoomMarkers(currentRoom.id);
  };
  const handleCopyMarker = () => {
    setCopyModalOpen(true);
  };
  const closeValidMarkersModal = () => {
    setValidMarkerModalOpen(false);
    setCurrent(0);
  };
  const nextSlide = () => {
    setCurrent(current === currentRoom.markers.length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? currentRoom.markers.length - 1 : current - 1);
  };
  const handleApproveMarker = async () => {
    const { id } = currentRoomMarkers[current];
    await approveMarker(currentRoom.listing_id, id);
    getRoomMarkers(currentRoom.id);
  };
  const handleRejectMarker = async () => {
    const { id } = currentRoomMarkers[current];
    await rejectMarker(id);
    getRoomMarkers(currentRoom.id);
  };
  const handleSearchNewListingRoom = async () => {
    if (Number(newCopyListingId) !== Number(listingId)) {
      const res = await fetchNewListing(Number(newCopyListingId));
      if (res) {
        setNewListingValid(true);
      }
    } else {
      alert('Please enter a different listing!');
    }
  };
  const getRoomName = (marker) => {
    const foundMarker = building?.markers.find((m) => {
      return m.id === marker;
    });
    if (foundMarker) {
      const foundRoom = allRooms.find((r) => {
        return r.id === foundMarker.room;
      });
      if (foundRoom) {
        return `${foundRoom.roomname}-${Floor.getName(foundRoom.floor.toString())}`;
      }
      return null;
    }
    return null;
  };
  const isValid = () => {
    if (!newRoomId) {
      return true;
    }
    return false;
  };
  const logoClick = () => {
    window.open('https://www.hausvalet.ca');
  };
  return (
    <Box>
      <Box
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <img
          className={classes.logo}
          src={images.logoHorizontal}
          alt='Logo'
          onClick={logoClick}
        />
      </Box>
      <Grid container className='floorplan_editor_content'>
        <Grid item md={8} className={classes.floorplanContainer}>
          <Box className={classes.rooms}>
            {roomPlans.map((m: RoomPlan) => {
              const roomBoxStyle = {
                top: m.topMargin,
                left: m.leftMargin,
                width: m.roomWidth,
                height: m.roomHeight,
              };

              const roomStyle = {
                color: m.textColor,
                fontSize: m.fontSize,
                background: m.background,
                cursor: m.cursor,
              };

              const roomItem = m.icon ? (
                <img src={`/images/icons/${m.icon}.png`} alt='any' />
              ) : (
                m.displayName
              );
              return (
                <div
                  key={m.room.id}
                  className={classes.roomBox}
                  style={roomBoxStyle}
                  onClick={() => {
                    return setCurrentRoom(m.room);
                  }}
                  onTouchEnd={() => {
                    return setCurrentRoom(m.room);
                  }}
                >
                  <div className={classes.room} style={roomStyle}>
                    {roomItem}
                  </div>
                </div>
              );
            })}
          </Box>
          <Box className={classes.floors}>
            {floors.map((f) => {
              return (
                <Box key={f.id}>
                  <Button
                    className={
                      f.selected ? classes.currentFloorplanButton : classes.floorButton
                    }
                    type='button'
                    fullWidth
                    variant='contained'
                    onClick={() => {
                      return setCurrentFloor(f.id);
                    }}
                  >
                    <Typography
                      className={
                        f.selected ? classes.selectedButtonText : classes.floorButtonText
                      }
                    >
                      {f.name}
                    </Typography>
                  </Button>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid item md={3} className={classes.inputsGroup}>
          <Box className={classes.row}>
            <Box>
              <TextField
                variant='outlined'
                value={x}
                type='number'
                inputProps={{ step: 20 }}
                onChange={handleXChange}
                onBlur={handleXSave}
                margin='normal'
                label='X'
                size='small'
                className={classes.input}
              />
            </Box>
            <Box>
              <TextField
                variant='outlined'
                value={y}
                type='number'
                inputProps={{ step: 20 }}
                onChange={handleYChange}
                onBlur={handleYSave}
                margin='normal'
                label='Y'
                size='small'
                className={classes.input}
              />
            </Box>
          </Box>
          <Box className={classes.row}>
            <TextField
              variant='outlined'
              value={width}
              type='number'
              inputProps={{ min: 0, max: 200 }}
              onChange={handleWidthChange}
              onBlur={(e) => {
                return handleDimensionChange('width', e);
              }}
              margin='normal'
              label='W'
              size='small'
              className={classes.input}
            />
            <TextField
              variant='outlined'
              value={height}
              type='number'
              inputProps={{ min: 0, max: 200 }}
              onChange={handleHeightChange}
              onBlur={(e) => {
                return handleDimensionChange('height', e);
              }}
              margin='normal'
              label='H'
              size='small'
              className={classes.input}
            />
          </Box>
          <Box className={classes.row}>
            <TextField
              variant='outlined'
              className={classes.input}
              value={width_inches}
              inputProps={{ min: 0, max: 11 }}
              type='number'
              onChange={handleWidthInchesChange}
              onBlur={(e) => {
                return handleDimensionChange('width_inches', e);
              }}
              margin='normal'
              label='W (inches)'
              size='small'
            />
            <TextField
              variant='outlined'
              value={height_inches}
              inputProps={{ min: 0, max: 11 }}
              type='number'
              onChange={handleHeightInchesChange}
              onBlur={(e) => {
                return handleDimensionChange('height_inches', e);
              }}
              margin='normal'
              label='H (inches)'
              size='small'
              className={classes.input}
            />
          </Box>
          <Box className={classes.form}>
            <FormControl className={classes.row}>
              <Typography>Irregular</Typography>
              <Checkbox
                className={classes.checkbox}
                color='primary'
                checked={irregular}
                onChange={handleShapeSelect}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id='room-select-input'>Room name</InputLabel>
              <Select
                labelId='room-select-label'
                id='room-select'
                value={roomname}
                className={classes.select}
                onChange={handleRoomSelect}
              >
                {floor_rooms.length &&
                  floor_rooms.map((r, index) => {
                    return (
                      <MenuItem className={classes.select} key={r.key} value={r.key}>
                        <Typography>{r.key}</Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
              <Box className={classes.row}>
                <Typography>{`Room id: ${currentRoom.id}`}</Typography>
              </Box>
            </FormControl>
            <FormControl className={classes.formControl} size='small'>
              <InputLabel id='floor-type-select-input'>Floor type</InputLabel>
              <Select
                labelId='floor-type-select-label'
                id='floor-type-select'
                value={floor_type}
                className={classes.select}
                onChange={handleFloorTypeSelect}
              >
                {floor_types.length &&
                  floor_types.map((f, index) => {
                    return (
                      <MenuItem className={classes.select} key={f.key} value={f.key}>
                        <Typography>{f.key}</Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} size='small'>
              <InputLabel id='floor-select'>Floor</InputLabel>
              <Select
                labelId='floor-select-label'
                id='floor-select'
                className={classes.select}
                value={floor}
                onChange={handleFloorSelect}
              >
                {FLOOR_LEVELS.map((f, index) => {
                  return (
                    <MenuItem className={classes.select} key={index} value={f}>
                      <Typography>{f}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Button
              className={classes.button}
              type='button'
              variant='contained'
              color='primary'
              onClick={handleValidMarkers}
            >
              <Typography className={classes.buttonText}>Valid Markers</Typography>
            </Button>
            <Button
              className={classes.button}
              type='button'
              variant='contained'
              color='primary'
              onClick={handleReorderRooms}
            >
              <Typography className={classes.buttonText}>Reorder markers</Typography>
            </Button>
            <Button
              className={classes.button}
              type='button'
              variant='contained'
              color='primary'
              onClick={handleMoveRoom}
            >
              <Typography className={classes.buttonText}>Move floorplan room</Typography>
            </Button>
            <Button
              className={classes.button}
              type='button'
              variant='contained'
              color='primary'
              onClick={handleCopyMarker}
            >
              <Typography className={classes.buttonText}>
                Copy floorplan markers
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Dialog
          open={isMoveModalOpen}
          onClose={() => {
            return setMoveModalOpen(false);
          }}
        >
          <DialogTitle className={classes.row}>
            <Typography>Move floorplan room</Typography>
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={() => {
                return setMoveModalOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography>
                {`Please enter the new listing id this ${currentRoom.roomname} belongs to:`}
              </Typography>
              <Box className={classes.row}>
                <TextField
                  variant='outlined'
                  className={classes.input}
                  value={currentRoom.listing_id}
                  margin='normal'
                  label='Current listing id'
                  size='small'
                  disabled
                />
                <TextField
                  variant='outlined'
                  value={new_listing_id}
                  onChange={handleChangeListingId}
                  margin='normal'
                  label='New listing id'
                  size='small'
                />
              </Box>
            </Box>
            <Box>
              <Box className={classes.row}>
                <TextField
                  variant='outlined'
                  className={classes.input}
                  value={currentRoom.shooting_id}
                  margin='normal'
                  label='Current shooting id'
                  size='small'
                  disabled
                />
              </Box>
            </Box>
            <Box className={classes.row}>
              <Checkbox
                className={classes.checkbox}
                color='primary'
                checked={keepRoom}
                onChange={handleKeepRoomOption}
              />
              <Typography>Keep original copy of the room in this listing</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='primary' onClick={handleMoveSave}>
              <Typography className={classes.buttonText}>Save</Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isCopyModalOpen}
          onClose={() => {
            return setCopyModalOpen(false);
          }}
        >
          <DialogTitle className={classes.row}>
            <Typography>{`Copy ${currentRoom.roomname} markers to a new listing`}</Typography>
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={() => {
                return setCopyModalOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography className={classes.subtitle}>
                Please enter the new listing id which you want to copy to:
              </Typography>
              <Box className={classes.row}>
                <TextField
                  variant='outlined'
                  className={classes.input}
                  value={currentRoom.listing_id}
                  margin='normal'
                  label='Current listing id'
                  size='small'
                  disabled
                />
                <TextField
                  variant='outlined'
                  value={newCopyListingId}
                  onChange={handleChangeCopyListingId}
                  onBlur={handleSearchNewListingRoom}
                  margin='normal'
                  label='New listing id'
                  size='small'
                />
              </Box>
            </Box>
            <Box>
              <Box className={classes.row}>
                <TextField
                  variant='outlined'
                  className={classes.input}
                  value={currentRoom.shooting_id}
                  margin='normal'
                  label='Current shooting id'
                  size='small'
                  disabled
                />
              </Box>
              <Box>
                {isNewListingValid ? (
                  <Box className={classes.row}>
                    <Typography className={classes.subtitle}>
                      You can find the new room from the listing
                      <a
                        target='_blank'
                        rel='nooopener noreferrer'
                        href={`/floorplan/${newCopyListingId}`}
                      >
                        {' '}
                        here
                      </a>
                    </Typography>
                  </Box>
                ) : null}

                <TextField
                  variant='outlined'
                  required
                  value={newRoomId || ''}
                  onChange={handleChangeNewRoomId}
                  margin='normal'
                  label='New room id'
                  size='small'
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isValid()}
              variant='contained'
              color='primary'
              onClick={handleCopySave}
            >
              <Typography className={classes.buttonText}>Save</Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isReOrderRoomsModalOpen}
          onClose={() => {
            return setReorderRoomsModalOpen(false);
          }}
        >
          <DialogTitle className={classes.row}>
            <Typography>Reorder floorplan rooms</Typography>
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={() => {
                return setReorderRoomsModalOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography>You can drag and reorder the rooms</Typography>
              {markersWithOrder.map((marker, index) => {
                return (
                  <Box
                    key={index}
                    className={classes.list}
                    draggable
                    onDragStart={(e) => {
                      dragItem.current = index;
                    }}
                    onDragEnter={(e) => {
                      dragOverItem.current = index;
                    }}
                    onDragEnd={handleSort}
                    onDragOver={(e) => {
                      return e.preventDefault();
                    }}
                  >
                    <Typography>{getRoomName(marker)}</Typography>
                    <ReorderIcon />
                  </Box>
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='primary' onClick={handleReorderSave}>
              <Typography className={classes.buttonText}>Save</Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isValidMarkerModalOpen}
          onClose={closeValidMarkersModal}
          onBackdropClick={closeValidMarkersModal}
        >
          <DialogTitle className={classes.row}>
            <Typography>{`${currentRoom.markers.length} markers`}</Typography>
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={closeValidMarkersModal}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box className={classes.slider}>
              <img
                src={images.prev}
                className={classes.leftArrow}
                onClick={prevSlide}
                alt='back'
              />
              <img
                src={images.next}
                className={classes.rightArrow}
                onClick={nextSlide}
                alt='next'
              />
              {currentRoomMarkers.map((marker: any, index) => {
                if (index === current) {
                  return (
                    <div
                      key={index}
                      className={index === current ? classes.activeSlide : classes.slide}
                    >
                      <img
                        src={marker.lowres_media_url}
                        style={{ height: 'auto', width: '100%' }}
                        alt='marker'
                      />
                    </div>
                  );
                }
                return null;
              })}
            </Box>
            <Box className={classes.scrollableRow}>
              {currentRoomMarkers.length &&
                currentRoomMarkers.map((marker: any, index) => {
                  return (
                    <Box
                      style={{
                        marginRight: 10,
                        border: current === index ? '2px solid #00bdf2' : null,
                      }}
                    >
                      <img
                        src={marker.lowres_media_url}
                        style={{
                          height: 'auto',
                          width: 200,
                        }}
                        onClick={() => {
                          return setCurrent(index);
                        }}
                        alt='marker'
                      />
                      {marker.state === 1 ? (
                        <Typography className={classes.valid}>Valid</Typography>
                      ) : null}
                    </Box>
                  );
                })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='secondary' onClick={handleRejectMarker}>
              <Typography className={classes.buttonText}>Reject</Typography>
            </Button>
            <Button variant='contained' color='primary' onClick={handleApproveMarker}>
              <Typography className={classes.buttonText}>Approve</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
}

export default FloorplanEditor;
