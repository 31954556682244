import _ from 'lodash';
import { FloorPlanDimensionsData } from 'types';

class Floor {
  id: number;

  name: string;

  selected: boolean;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }

  static getName(floor: string): string {
    switch (floor) {
      case '4':
        return `Étage ${5}`;
      case '3':
        return `Étage ${4}`;
      case '2':
        return `Étage ${3}`;
      case '1':
        return `Étage ${2}`;
      case '0':
        return 'Rez-de-chaussée';
      case '-1':
        return 'Sous-sol';
      case '-2':
        return 'Aire commune';
      default:
        return '';
    }
  }

  static getPlanDimensions = (width: number, height: number): FloorPlanDimensionsData => {
    const dimension = _.min([width, height]);

    if (dimension <= 600) {
      return {
        min_width: 100,
        min_height: 100,
        max_width: 200,
        max_height: 200,
      };
    }

    return {
      min_width: 100,
      min_height: 100,
      max_width: 400,
      max_height: 400,
    };
  };
}

export default Floor;
